import { filter, isNil, isEmpty, keys, map, reject } from 'ramda';

import { plaformUsageId } from '../constants/platforms';

export const isNilOrEmpty = (...args) => filter((val) => !isNil(val) && !isEmpty(val), args).length <= 0;

export const convertHashmapToArray = (hashmap = {}) =>
    isNilOrEmpty(hashmap) ? [] : map((key) => hashmap[key], keys(hashmap));

export const getCanSynchronisePlatform = (platform, customer, userLimits = {}) => {
    const { numberOfPlatformsAllowed = 0 } = userLimits;

    const { googleDataFeedId, facebookCatalogId, pinterestDataFeedId } = customer;
    const platformsUsed = reject(isNil, { facebookCatalogId, googleDataFeedId, pinterestDataFeedId });
    if (platformsUsed && platformsUsed[plaformUsageId[platform]]) return true;

    const numberOfPlatformsUsed = keys(platformsUsed).length;
    return numberOfPlatformsUsed < numberOfPlatformsAllowed;
};
