import firebase from 'firebase/compat/app';

import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';

console.log(process.env.GATSBY_FIREBASE_API_KEY);

// Configure getFirebase().
const config = {
    apiKey: process.env.GATSBY_FIREBASE_API_KEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
    storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.GATSBY_FIREBASE_APP_ID,
    measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID
};

// Configure FirebaseUI.
export const getUiConfig = (signInSuccessHandler = Function.prototype, signInLabel) => {
    return {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // We will display Google and Facebook as auth providers.
        signInOptions: [
            {
                provider: getFirebase()?.auth.GoogleAuthProvider.PROVIDER_ID,
                ...(signInLabel && { fullLabel: signInLabel })
            },
            getFirebase()?.auth.EmailAuthProvider.PROVIDER_ID
            // getFirebase()?.auth.FacebookAuthProvider.PROVIDER_ID
        ],
        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                signInSuccessHandler(authResult, redirectUrl);
            }
        }
    };
};

export const getConsumerUiConfig = (signInSuccessHandler = Function.prototype) => {
    return {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // We will display Google and Facebook as auth providers.
        signInOptions: [getFirebase()?.auth.PhoneAuthProvider.PROVIDER_ID],
        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                signInSuccessHandler(authResult, redirectUrl);
            }
        }
    };
};

let instance;

export function getFirebase() {
    if (typeof window !== 'undefined') {
        if (instance) return firebase;

        instance = firebase.initializeApp(config);
        return instance;
    }

    return {};
}
