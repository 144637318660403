import { useContext, useEffect, useState } from 'react';
import { head } from 'ramda';
import mime from 'mime';
import moment from 'moment';
import slugify from 'slugify';

import AppContext from '../context/app';
import { isNilOrEmpty } from '../utils';
import { getFirebase } from '../utils/signin';

const handleUploadImage = async (image, storeId, name) => {
    if (!image) return null;

    const storageRef = getFirebase().storage().ref();
    const extension = mime.getExtension(image.type);
    const imagePath = `images/${storeId}/${name}.${extension}`;
    const imageStorageRef = storageRef.child(imagePath);
    const snapshot = await imageStorageRef.put(image);
    const imageUrl = await snapshot.ref.getDownloadURL();
    return {
        imagePath,
        image: imageUrl
    };
};

const useStore = (slug) => {
    const firebase = getFirebase();
    const { user, isSignedIn, userLimits, isLoadingUser } = useContext(AppContext);
    const [store, setStore] = useState(null);
    const [storeDoc, setStoreDoc] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMutation, setIsLoadingMutation] = useState(false);

    const fetchWithQuery = async (...query) => {
        setIsLoading(true);
        const db = firebase.firestore();
        const storeCollection = db.collection('stores');
        await storeCollection.where(...query).onSnapshot((querySnapshot) => {
            if (isNilOrEmpty(querySnapshot.docs)) return setIsLoading(false);

            const doc = head(querySnapshot.docs);
            if (!doc) return setIsLoading(false);
            setStore(doc.data());
            setStoreDoc(doc.ref);
            setIsLoading(false);
        });
    };

    const fetchCurrentStore = () => fetchWithQuery('authorId', '==', user?.uid);
    const fetchStoreBySlug = () => fetchWithQuery('slug', '==', slug);

    const generateSlug = async (name) => {
        const slug = slugify(name, { lower: true });
        const db = firebase.firestore();
        const storeCollection = db.collection('stores');
        const querySnapshot = await storeCollection
            .where('slug', '>=', slug)
            .where('slug', '<=', slug + '\uf8ff')
            .get();
        const stores = querySnapshot.docs.map((doc) => doc.data());
        if (stores.length > 0) return `${slug}-${stores.length}`;
        return slug;
    };

    const uploadStoreImages = async (logo = [], cover = [], id) => {
        const logoUploadedImages = await handleUploadImage(head(logo), id, 'logo');
        const coverUploadedImages = await handleUploadImage(head(cover), id, 'cover');

        return {
            ...(logoUploadedImages && { logo: logoUploadedImages }),
            ...(coverUploadedImages && { cover: coverUploadedImages })
        };
    };

    useEffect(() => {
        if (!user) return;

        if (!slug) fetchCurrentStore();
        if (slug) fetchStoreBySlug();
    }, [user]);

    const createStore = async (data) => {
        if (!isSignedIn) return;

        try {
            const { logo, cover, name, ...rest } = data;
            setIsLoadingMutation(true);
            const db = firebase.firestore();
            const storeCollection = db.collection('stores');
            const storeDoc = await storeCollection.add({
                name: name.trim(),
                slug: await generateSlug(name),
                isTrial: userLimits?.isTrial ?? false,
                onlineOrderEnabled: userLimits?.onlineOrderEnabled ?? false,
                ...rest,
                authorId: user.uid,
                createdAt: moment().toISOString()
            });
            const images = await uploadStoreImages(logo, cover, storeDoc.id);
            await storeDoc.update({ ...images });
            const storeSnapshot = await storeDoc.get();
            setStoreDoc(storeDoc);
            setStore(storeSnapshot.data());
            setIsLoadingMutation(false);
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    const updateStore = async (data) => {
        if (!isSignedIn) return;

        try {
            const { logo, cover, name, ...rest } = data;
            setIsLoadingMutation(true);
            const updatedNameMaybe =
                (name &&
                    name.trim() != store.name && {
                        name: name.trim(),
                        slug: await generateSlug(name)
                    }) ||
                {};
            console.log('userLimits::onlineOrderEnabled', userLimits?.onlineOrderEnabled);
            await storeDoc.update({
                onlineOrderEnabled: userLimits?.onlineOrderEnabled ?? false,
                ...updatedNameMaybe,
                ...rest
            });
            const images = await uploadStoreImages(logo, cover, storeDoc.id);
            await storeDoc.update({ ...images });
            setStore((await storeDoc.get()).data());
            setIsLoadingMutation(false);
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    const createOrUpdateStore = (data) => {
        if (!storeDoc) createStore(data);
        if (storeDoc) updateStore(data);
    };
    const getAppleLoyaltyCard = async () => {
        setIsLoadingMutation(true);
        const accessToken = await firebase.auth().currentUser.getIdToken();
        const response = await fetch(
            `${process.env.GATSBY_FIREBASE_FUNCTIONS_URL}/generateApplePassLoyaltyCard`,
            {
                method: 'POST',
                body: JSON.stringify({
                    storeId: storeDoc.id
                }),
                headers: {
                    Authorization: 'Bearer ' + accessToken
                }
            }
        );
        async function readAllChunks(readableStream) {
            const reader = readableStream.getReader();
            const chunks = [];

            let done, value;
            while (!done) {
                ({ value, done } = await reader.read());
                if (done) {
                    return chunks;
                }
                chunks.push(value);
            }
        }
        const data = await readAllChunks(response.body);
        // console.log('response after', data);
        const blob = new Blob(data, { type: 'application/vnd.apple.pkpass' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Shopsync.pkpass';
        link.click();
        setIsLoadingMutation(false);
    };
    const getLoyaltyCard = async () => {
        return await getAppleLoyaltyCard();
    };
    const stampAppleLoyaltyCard = async (customerId) => {
        setIsLoading(true);
        console.log('StampLoyaltyCard props', { storeId: storeDoc.id, customerId });
        const stampAppleLoyaltyCard = firebase.functions().httpsCallable('stampAppleLoyaltyCard');
        await stampAppleLoyaltyCard({ storeId: storeDoc.id, customerId });
        setIsLoading(false);
    };

    return {
        store,
        storeDoc,
        isLoading,
        isLoadingMutation,
        setIsLoadingMutation,
        createOrUpdateStore,
        getLoyaltyCard,
        stampAppleLoyaltyCard
    };
};

export { useStore };
