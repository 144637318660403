import { useState } from 'react';

export const useOnboarding = () => {
    const [previousPath, setPreviousPath] = useState('/');
    const [progress, setProgress] = useState('0');

    return {
        previousPath,
        progress,
        setPreviousPath,
        setProgress
    };
};
