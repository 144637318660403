import { useEffect, useState } from 'react';
import { path } from 'ramda';

import { getUserLimits } from '../client/dashboard/account';
import { isNilOrEmpty } from '../utils';
import { getFirebase } from '../utils/signin';

const useUser = () => {
    const firebase = getFirebase();
    const [user, setUser] = useState(null);
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [userHasPlan, setUserHasPlan] = useState(false);
    const [isLoadingUser, setIsLoadingUser] = useState(true);
    const [userLimits, setUserLimits] = useState({});
    const [customer, setCustomer] = useState(null);
    const [customerIsLoading, setCustomerIsLoading] = useState(false);

    useEffect(() => {
        if (isNilOrEmpty(firebase)) return;

        // CONNECT TO FUNCTIONS LOCALLY, DO NOT USE FOR PRODUCTION
        // getFirebase().functions().useEmulator('127.0.0.1', 5001);

        const unregisterAuthObserver = getFirebase()
            .auth()
            .onAuthStateChanged(async (newUser) => {
                setUser(newUser);
                const createdAt = path(['metadata', 'createdAt'], newUser);
                const limits = newUser && (await getUserLimits(newUser.uid, parseInt(createdAt)));
                console.log('user::limits:>', newUser, createdAt, limits);
                setUserLimits(limits);
                setIsSignedIn(!!newUser);
                if (newUser) {
                    if (typeof window !== 'undefined') {
                        window.sendinblue.identify(newUser.email, {
                            FIRSTNAME: newUser.displayName
                            // 'WEBSITE': 'www.foo.com'
                        });
                    }
                    await fetchCustomer(newUser?.uid, newUser?.email);
                    const db = firebase.firestore();
                    db.collection('customers')
                        .doc(newUser?.uid)
                        .collection('subscriptions')
                        .where('status', 'in', ['trialing', 'active'])
                        .onSnapshot(async (snapshot) => {
                            console.log('user', user);
                            if (!snapshot.empty) {
                                setUserHasPlan(true);
                                return;
                            }
                        });
                }
                setIsLoadingUser(false);
            });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    const createCustomer = async (data = {}, userUid = user?.uid) => {
        setCustomerIsLoading(true);
        const db = firebase.firestore();
        await db.collection('customers').doc(userUid).set(data, { merge: true });
        await fetchCustomer();
        setCustomerIsLoading(false);
    };

    const fetchCustomer = async (userUid = user?.uid, email = null) => {
        setCustomerIsLoading(true);
        const db = firebase.firestore();
        const doc = await db.collection('customers').doc(userUid).get();
        console.log('doc', doc, doc.empty, email);
        if (!doc.exists && email) {
            await db.collection('customers').doc(userUid).set({
                email
            });
        }
        setCustomer(doc.data());
        setCustomerIsLoading(false);
    };

    const updateCustomer = async (data, userUid = user?.uid) => {
        setCustomerIsLoading(true);
        const db = firebase.firestore();
        const doc = await db.collection('customers').doc(userUid).get();
        await doc.ref.update(data);
        await fetchCustomer();
        setCustomerIsLoading(false);
    };

    const trackEvent = async (step) => {
        try {
            const track = firebase.functions().httpsCallable('brev');
            await track({ nm: step });
        } catch (e) {}
    };

    return {
        user,
        isSignedIn,
        userHasPlan,
        isLoadingUser,
        userLimits,
        customer,
        customerIsLoading,
        createCustomer,
        fetchCustomer,
        updateCustomer,
        trackEvent
    };
};

export { useUser };
