import { useContext, useEffect, useRef, useState } from 'react';
import { path } from 'ramda';

import AppContext from '../context/app';
import { getFirebase } from '../utils/signin';

export const useHeroku = () => {
    const firebase = getFirebase();
    const { customer, fetchCustomer, updateCustomer } = useContext(AppContext);
    const [herokuIsLoading, setHerokuIsLoading] = useState(false);
    const [managerStatus, setManagerStatus] = useState('pending');
    const customerRef = useRef();
    customerRef.current = customer;
    const handleGetSetupStatus = async () => {
        try {
            const appSetupId = path(['current', 'herokuAppSetupId'], customerRef);
            const appId = path(['current', 'herokuAppId'], customerRef);

            if (!appSetupId && !appId) return;
            if (managerStatus === 'up') return;

            if (appSetupId) {
                const getSetupStatusFunction = firebase.functions().httpsCallable('getSetupStatus');
                const response = await getSetupStatusFunction({ appSetupId });
                const status = response.data.status;
                const setupSucceeded = status === 'succeeded';
                if (setupSucceeded) await updateCustomer({ herokuAppSetupId: null });
                setManagerStatus(setupSucceeded ? 'up' : status);
            } else {
                const getManagerStatusFunction = firebase.functions().httpsCallable('getManagerStatus');
                const response = await getManagerStatusFunction({ appId });
                setManagerStatus(response.data.status);
            }
        } catch (e) {
            console.log('error', e);
        }
    };
    useEffect(() => {
        handleGetSetupStatus();
    }, []);

    const setupManager = async (configVars) => {
        setHerokuIsLoading(true);
        const setupManagerFunction = firebase.functions().httpsCallable('setupManager');
        await setupManagerFunction(configVars);
        await fetchCustomer();
        setHerokuIsLoading(false);
    };

    const updateManagerConfigVars = async (configVars) => {
        setHerokuIsLoading(true);
        const updateManagerConfigVarsFunction = firebase.functions().httpsCallable('updateManagerConfigVars');
        await updateManagerConfigVarsFunction(configVars);
        await fetchCustomer();
        setHerokuIsLoading(false);
    };

    return {
        setupManager,
        updateManagerConfigVars,
        managerStatus,
        herokuIsLoading
    };
};
