import { useContext, useEffect, useState } from 'react';
import { length, path } from 'ramda';

import AppContext from '../context/app';
import { getFirebase } from '../utils/signin';

export const useSharetribe = () => {
    const firebase = getFirebase();
    const { customer } = useContext(AppContext);
    const [marketplaceDataPaths, setMarketplaceDataPaths] = useState([]);
    const [sharetribeIsLoading, setSharetribeIsLoading] = useState(false);

    console.log('marketplaceDataPaths', marketplaceDataPaths);

    useEffect(() => {
        (async () => {
            const appId = path(['herokuAppId'], customer);
            console.log('appId', appId);

            if (!appId) return;
            if (length(marketplaceDataPaths) > 0) return;

            setSharetribeIsLoading(true);
            const dataPaths = await getMarketplaceDataPaths(appId);
            setMarketplaceDataPaths(['default', ...dataPaths.data.paths]);
            setSharetribeIsLoading(false);
        })();
    }, []);

    const getMarketplaceDataPaths = async (herokuAppId) => {
        const getMarketplaceDataPathsFunction = firebase.functions().httpsCallable('getMarketplaceDataPaths');
        const dataPaths = await getMarketplaceDataPathsFunction({ herokuAppId });
        console.log('dataPaths', dataPaths);

        return dataPaths;
    };

    return {
        marketplaceDataPaths,
        sharetribeIsLoading
    };
};
